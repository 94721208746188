ul.splits {
    font-family: "Circular Std";
    color: #2b2b2b;
    line-height: 1.428571429;
    outline: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
}


.splits__link:hover {
    color: white;
    background: rgba(28, 33, 108, 0.5);
}

.splits__item {
    float: left;
    width: 11.11%;
    border-right: 1px solid #c4c3c3;
}

.increment__item {
    float: left;
    width: 50%;
    border-right: 1px solid #c4c3c3;
}

.delivery__item {
    float: left;
    width: 20%;
    border-right: 1px solid #c4c3c3;
}

.splits__link.state__active {
    color: white;
    background: #1c216c;

}

.splits__link {
    display: block;
    text-decoration: none;
    text-align: center;
    color: #818284;
    padding: 4px 0 5px;
    line-height: 19px;
    cursor: pointer;
}